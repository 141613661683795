import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  width: 100%;
  article {
    margin-bottom: 4rem;
  }

  ${MEDIA.MIN_LAPTOP`
    article {
      margin-bottom: 6rem;
    }
  `};

  .article__image {
    margin-bottom: 2rem;
  }

  ${MEDIA.MIN_LAPTOP`
    .article__image {
      margin-bottom: 3rem;
    }
  `};

  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h1,
  h2,
  h3 {
    margin-bottom: 2rem;
    font-weight: 500;
    line-height: 1.2;
  }

  ol,
  ul {
    margin-bottom: 3rem;
    margin-left: 2rem;
  }

  ul {
    list-style: disc;
  }

  .lead {
    margin-bottom: 4rem;
    text-align: center;
  }

  .title {
    font-size: 2.2rem;
  }

  .items {
    margin-bottom: 4rem;

    ${MEDIA.MIN_TABLET`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
    `};

    ${MEDIA.MIN_LAPTOP`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 4rem;
    `};
  }

  .item {
    ${MEDIA.PHONE`
      margin-bottom: 4rem;
    `};
  }
`;
