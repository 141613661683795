import React from 'react';
import logo from './logo.svg';
import { Wrapper } from './logo.css';

const Logo = () => (
  <Wrapper>
    <img src={logo} alt="Trade in Cool Logo" />
  </Wrapper>
);

export default Logo;
