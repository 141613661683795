import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Title from 'components/title';
import Head from 'components/head';
import Logo from 'components/logo';
import MarkdownWrapper from 'components/markdownwrapper';
import VisuallyHidden from '@reach/visually-hidden';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const Grid = styled.article`
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-template-columns: 250px 1fr;
  grid-gap: 4rem;
  padding: 0 15px 0 15px;
  margin: 2rem 0;
  display: block;

  ${MEDIA.MIN_TABLET`
    display: grid;
  `};

  ${MEDIA.MIN_LAPTOP`
    display: block;
    padding: 0 20%;

    .article__image {
      margin-left: -5rem;
      margin-right: -5rem;
    }
  `};
`;

const centerItems = {
  textAlign: 'center',
};

const smallHeading = {
  fontSize: '1.6rem',
  color: '#333333',
};

const Articles = ({ data }) => (
  <Layout>
    <Head pageTitle="Articles" />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <div style={centerItems}>
            <Logo />
            <Title as="h1" size="large">
              Articles
            </Title>
          </div>
        </Col>
      </Row>
    </Container>
    <div style={{ height: '5vh' }} />
    <Container>
      <Row>
        <MarkdownWrapper>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Grid key={node.id} className="article">
              <Img
                className="article__image"
                fluid={node.frontmatter.teaserImage.childImageSharp.fluid}
              />
              <div className="article__content">
                <h2 className="article__title">
                  {node.frontmatter.title}{' '}
                  <span style={smallHeading}>— {node.frontmatter.date}</span>
                </h2>
                <p>{node.excerpt}</p>
                <Link to={node.frontmatter.slug} className="article__link">
                  Read More{' '}
                  <VisuallyHidden>
                    about {node.frontmatter.titleMore}
                  </VisuallyHidden>
                </Link>
              </div>
            </Grid>
          ))}
        </MarkdownWrapper>
      </Row>
    </Container>
  </Layout>
);

Articles.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Articles;

export const query = graphql`
  query ArticlesQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          type: { eq: "article" }
          title: { ne: "" }
          draft: { eq: "false" }
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            titleMore
            date(formatString: "MMMM DD, YYYY")
            draft
            type
            slug
            teaserImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1240) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
